import { styled } from '@mui/system';
import React from 'react';
import {
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Apps,
  CloudUpload,
  Cloud,
  Home,
  LibraryBooks,
  RssFeed,
  AccountBox,
  Visibility,
  Edit,
} from '@mui/icons-material';
import { DistributiveOmit } from '@mui/types';
import { NavLink, useLocation } from 'react-router-dom';
import SmartContext from './SmartContext';
import Branding from './Branding';
import { withTranslation, WithTranslation } from 'react-i18next';

const features = {
  upload: true,
  syndication: true,
  apps: false,
};

type MenuType = {
  id: string;
  disable?: boolean;
  icon?: any;
  active?: boolean;
  href?: string;
  target?: string;
}

const categories: { id: string, title?: string, disable?: boolean, children: MenuType[] }[] = [
  {
    id: 'Admin',
    title: 'Admin',
    children: [
      { id: 'About', icon: <Home/>, href: '/about' },
      { id: 'Resources', icon: <LibraryBooks/>, href: '/resource', active: true },
      { id: 'Syndication', icon: <RssFeed/>, href: '/upstream', disable: !features.syndication },
      { id: 'UploadSNOMED', icon: <CloudUpload/>, href: '/upload-sct', disable: !features.upload },
      // { id: 'Syndication (local)', icon: <RssFeed />, href: '/syndication', disable: !features.syndication },

      // { id: 'Storage', icon: <PermMediaOutlinedIcon /> },
      // { id: 'Functions', icon: <SettingsEthernetIcon /> },
      // { id: 'ML Kit', icon: <SettingsInputComponentIcon /> },
      // { id: 'Test', href: '/test' },
      { id: 'Applications', icon: <Apps/>, href: '/applications', disable: true },
    ],
  },
  {
    id: 'Apps',
    title: 'Apps',
    disable: !features.apps,
    children: [
      { id: 'Shrimp', href: '/shrimp/app', icon: <Visibility/> },
      { id: 'Snapper', href: '/snapper/app', icon: <Edit/> },
    ],
  },
];

if (process.env.NODE_ENV === 'development') {
  categories.unshift({
    id: 'Endpoint',
    children: [
      { id: 'Launch', icon: <Cloud/>, href: '/login' },
    ],
  });
}

const HeaderListItemComponent = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const ListItemTextComponent = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const ListItemPrimaryComponent = styled(ListItemText)({
  fontSize: 14,
});

const ListItemIconComponent = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
  marginRight: theme.spacing(2),
}));

const BlankComponent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(0),
}));

export interface NavigatorProps extends WithTranslation, DistributiveOmit<DrawerProps, 'classes'> {
  defaultUrl?: string;
}

const shrimpUrl = process.env.REACT_APP_SHRIMP ?? 'https://ontoserver.csiro.au/shrimp/launch.html';
const snapperUrl = process.env.REACT_APP_SNAPPER ?? '/snapper/';	// Default assumes AdminUI and Snapper are deployed as siblings

const shrimp =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost/~law223/shrimp-src/launch.html'
    : shrimpUrl;
const snapper =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost/~law223/snapper2-dev/'
    : snapperUrl;

function Navigator(props: NavigatorProps) {
  const { defaultUrl, t, ...other } = props;

  const smart = React.useContext(SmartContext);
  const account_endpoint = smart.onto?.account_endpoint;
  const fhirClient = smart.client;
  const [special, setSpecial] = React.useState(false);

  React.useEffect(() => {
    const clientUrl = fhirClient?.state?.serverUrl;
    // Only enable for local testing or "ontoserver-based" URLs
    setSpecial(!(clientUrl && clientUrl.indexOf('ontoserver') < 0 && clientUrl.indexOf('localhost') < 0));
  }, [fhirClient]);

  React.useEffect(() => {
    categories.forEach(cat => {
      cat.children.forEach(child => {
        if (child.href === '/upload-sct') {
          child.disable = child.disable || !smart.onto?.canUpload;
        }
      });
    });
  }, [smart.onto]);

  const location = useLocation();

  return (
    <Drawer variant="permanent" slotProps={other.slotProps} >
      <List disablePadding style={{ height: '99%' }}>
        <ListItem>
          <Branding/>
        </ListItem>
        <li><Divider/></li>
        {categories.filter(cat => !cat?.disable).map(({ id, title, children }) => (
          <React.Fragment key={id}>
            {title ? <HeaderListItemComponent>
              <ListItemTextComponent primary={t(title)}/>
            </HeaderListItemComponent> : <li><BlankComponent/></li>}
            {children.filter(cat => !cat?.disable).map(({ id: childId, href = '/', icon, target }) => (
              <li key={childId}>
                <ListItemButton
                  component={NavLink} to={href} target={target} hidden={'Syndication' === childId && !special}
                  aria-current="location"
                  selected={href === location.pathname}
                >
                  <ListItemIconComponent>{icon}</ListItemIconComponent>
                  <ListItemText
                    sx={{ '& .MuiListItemText-primary': { fontSize: 'inherit' } }}
                    primary={t(childId)}/>
                </ListItemButton>
              </li>
            ))}
            <li><Divider/></li>
          </React.Fragment>
        ))}
        <HeaderListItemComponent>
          <ListItemTextComponent primary={t('Apps')}/>
        </HeaderListItemComponent>
        {account_endpoint &&
          <li>
            <ListItemButton component="a" href={account_endpoint} target="_blank">
              <ListItemIconComponent><AccountBox/></ListItemIconComponent>
              <ListItemPrimaryComponent primary={t('Account')}/>
            </ListItemButton>
          </li>
        }
        <li>
          <ListItemButton
            component="a" href={`${shrimp}?iss=${fhirClient?.state?.serverUrl}`} target="_blank"
          >
            <ListItemIconComponent><Visibility/></ListItemIconComponent>
            <ListItemPrimaryComponent primary="Shrimp"/>
          </ListItemButton>
        </li>
        <ListItemButton
          component="a" href={`${snapper}?iss=${fhirClient?.state?.serverUrl}`} target="_blank"
        >
          <ListItemIconComponent><Edit/></ListItemIconComponent>
          <ListItemPrimaryComponent primary="Snapper"/>
        </ListItemButton>
        <li><Divider/></li>
      </List>
    </Drawer>
  );
}

export default withTranslation()(Navigator);
