
import React from 'react';
// import { R4 } from '@ahryman40k/ts-fhir-types';
import { Alert, Snackbar, Paper, CardContent, Card, Icon, Badge, Chip } from '@mui/material'
import { Difference, NoteAdd, RssFeed } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import { withTranslation } from 'react-i18next';
import SmartContext from './SmartContext';
import { Narrative } from './Narrative';
import { ResourceDetails } from './ResourceDetails';
import { ResourceHistory } from './ResourceHistory';
import { ResourceSyndication } from './ResourceSyndication';
import Validation from './Validation';
import FhirPatchView from './FhirPatchView';
import ResourceCompare from './ResourceCompare';
import ResourceUpload from './ResourceUpload';

const BLANK = {
  icon: () => <Icon>none</Icon>,
  disabled: true,
  render: () => <div></div>
};

// class MTableGroupRowPaged extends React.Component<any, any> {
//
//   constructor(props: any) {
//     super(props)
//     this.state = {
//       page: 0,
//     }
//   }
//
//   rotateIconStyle = (isOpen: boolean) => ({
//     transform: isOpen ? "rotate(90deg)" : "none",
//   });
//
//   render() {
//     let colSpan = this.props.columns.filter((columnDef: any) => !columnDef.hidden)
//       .length;
//     this.props.options.selection && colSpan++;
//     this.props.detailPanel && colSpan++;
//     this.props.actions && this.props.actions.length > 0 && colSpan++;
//     const column = this.props.groups[this.props.level];
//     const rpp = 10;
//
//     let detail;
//     let paging;
//     if (this.props.groupData.isExpanded) {
//       if (this.props.groups.length > this.props.level + 1) {
//         // Is there another group
//         detail = this.props.groupData.groups.map((groupData: any, index: number) => (
//           <this.props.components.GroupRow
//             actions={this.props.actions}
//             key={groupData.value || "" + index}
//             columns={this.props.columns}
//             components={this.props.components}
//             detailPanel={this.props.detailPanel}
//             getFieldValue={this.props.getFieldValue}
//             groupData={groupData}
//             groups={this.props.groups}
//             icons={this.props.icons}
//             level={this.props.level + 1}
//             path={[...this.props.path, index]}
//             onGroupExpandChanged={this.props.onGroupExpandChanged}
//             onRowSelected={this.props.onRowSelected}
//             onRowClick={this.props.onRowClick}
//             onToggleDetailPanel={this.props.onToggleDetailPanel}
//             onTreeExpandChanged={this.props.onTreeExpandChanged}
//             onEditingCanceled={this.props.onEditingCanceled}
//             onEditingApproved={this.props.onEditingApproved}
//             options={this.props.options}
//             hasAnyEditingRow={this.props.hasAnyEditingRow}
//             isTreeData={this.props.isTreeData}
//             cellEditable={this.props.cellEditable}
//             onCellEditStarted={this.props.onCellEditStarted}
//             onCellEditFinished={this.props.onCellEditFinished}
//             scrollWidth={this.props.scrollWidth}
//           />
//         ));
//       } else {
//         if (this.props.groupData.data.length > rpp) {
//           paging = (<div style={{ float: 'right' }}>
//             <MTablePagination
//               icons={this.props.icons}
//               page={this.state.page}
//               rowsPerPage={rpp}
//               showFirstLastPageButtons={false}
//               count={this.props.groupData.data.length}
//               onChangePage={(event: any, n: number) => { this.setState({ page: n }) }}
//             />
//           </div>);
//         }
//         const offset = this.state.page * rpp;
//         const pageData = this.props.groupData.data.slice(offset, offset + rpp);
//         detail = pageData.map((rowData: any, index: number) => {
//           if (rowData.tableData.editing) {
//             return (
//               <this.props.components.EditRow
//                 columns={this.props.columns}
//                 components={this.props.components}
//                 data={rowData}
//                 icons={this.props.icons}
//                 path={[...this.props.path, offset + index]}
//                 localization={this.props.localization}
//                 key={offset + index}
//                 mode={rowData.tableData.editing}
//                 options={this.props.options}
//                 isTreeData={this.props.isTreeData}
//                 detailPanel={this.props.detailPanel}
//                 onEditingCanceled={this.props.onEditingCanceled}
//                 onEditingApproved={this.props.onEditingApproved}
//                 getFieldValue={this.props.getFieldValue}
//                 onBulkEditRowChanged={this.props.onBulkEditRowChanged}
//                 scrollWidth={this.props.scrollWidth}
//               />
//             );
//           } else {
//             return (
//               <this.props.components.Row
//                 actions={this.props.actions}
//                 key={offset + index}
//                 columns={this.props.columns}
//                 components={this.props.components}
//                 data={rowData}
//                 detailPanel={this.props.detailPanel}
//                 getFieldValue={this.props.getFieldValue}
//                 icons={this.props.icons}
//                 path={[...this.props.path, offset + index]}
//                 onRowSelected={this.props.onRowSelected}
//                 onRowClick={this.props.onRowClick}
//                 onToggleDetailPanel={this.props.onToggleDetailPanel}
//                 options={this.props.options}
//                 isTreeData={this.props.isTreeData}
//                 onTreeExpandChanged={this.props.onTreeExpandChanged}
//                 onEditingCanceled={this.props.onEditingCanceled}
//                 onEditingApproved={this.props.onEditingApproved}
//                 hasAnyEditingRow={this.props.hasAnyEditingRow}
//                 cellEditable={this.props.cellEditable}
//                 onCellEditStarted={this.props.onCellEditStarted}
//                 onCellEditFinished={this.props.onCellEditFinished}
//                 scrollWidth={this.props.scrollWidth}
//               />
//             );
//           }
//         });
//       }
//     }
//
//     const freeCells = [];
//     for (let i = 0; i < this.props.level; i++) {
//       freeCells.push(<TableCell padding="checkbox" key={i} />);
//     }
//
//     let value = this.props.groupData.value;
//     if (column.lookup) {
//       value = column.lookup[value];
//     }
//
//     let title = column.title;
//     if (typeof this.props.options.groupTitle === "function") {
//       title = this.props.options.groupTitle(this.props.groupData);
//     } else if (typeof title !== "string") {
//       title = React.cloneElement(title);
//     }
//
//     let separator = this.props.options.groupRowSeparator || ": ";
//
//     return (
//       <>
//         <TableRow>
//           {freeCells}
//           <this.props.components.Cell
//             colSpan={colSpan}
//             padding="none"
//             columnDef={column}
//             value={value}
//             icons={this.props.icons}
//           >
//             <IconButton
//               style={{
//                 transition: "all ease 200ms",
//                 ...this.rotateIconStyle(this.props.groupData.isExpanded),
//               }}
//               onClick={(event) => {
//                 this.props.onGroupExpandChanged(this.props.path);
//               }}
//             >
//               <this.props.icons.DetailPanel />
//             </IconButton>
//             <b>
//               {title}
//               {separator}
//             </b>
//             {paging}
//           </this.props.components.Cell>
//         </TableRow>
//         {detail}
//       </>
//     );
//   }
// }

const getTitle = (data: any) => {
  return data.resource.title || data.resource.name || ' id:'+data.resource.id || ''
}

export const ResourcesCard = withTranslation()(({ avatar = '', type = 'ValueSet', t }) => {
  const smart = React.useContext(SmartContext);
  const fhirClient = smart.client

  const [loading, setLoading] = React.useState(false)
  const [count, setCount] = React.useState()
  const [entries, setEntries] = React.useState<any[]>([])
  const [alert, setAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };

  const translations = {
    compareResources: t('Compare Resources'),
    uploadResource: t('Upload Resource', {type}),
    confirmDelete: t('Really delete Resource'),
    deleteResource: t('Delete Resource'),
    id: t('Id'),
    title: t('Title'),
    url: t('URL'),
    version: t('Version'),
    status: t('Status'),
    domain: t('Domain'),
    lastUpdated: t('Last Updated'),
    publisher: t('Publisher'),
    description: t('Description'),
    purpose: t('Purpose'),
    tags: t('Tags'),
    synd: t('Synd'),
    validation: t('Validation'),
    narrative: t('Narrative'),
    changes: t('Changes'),
    history: t('History'),
    details: t('Details'),
  };

  React.useEffect(() => {
    smart.onto?.clearCache();
  }, [smart, type]);

  React.useEffect(() => {
    let mounted = true;
    setLoading(true)
    let url = `/${type}?_count=0`;
    url = `/${type}?_elements=id,name,title,url,version,status,publisher,description,purpose,identifier,compose,date,experimental,useContext,copyright,sourceScope,targetScope&_count=10000`;
    fhirClient?.request(url)
      .then((bundle: any) => {
        if (mounted) {
          setLoading(false)
          setCount(bundle.total || (bundle.entry?.length + '+'));
          bundle.entry?.forEach((e: any) => {
            e.resourceName = getTitle(e)
            if (e.resource.url) {
              try { e.domain = new URL(e.resource.url).hostname }
              catch (error) { e.domain = 'Invalid URL' }
            }
          })
          setEntries(bundle.entry || []);
        }
      }, (error: any) => {
        if (mounted) {
          setLoading(false)
          const msg = error?.message;
          if (msg) {
            if (msg.startsWith('This app cannot be accessed directly.')) {
              setMessage(t('AuthRequired', {type}))
            } else {
              setMessage(msg);
            }
            console.log('ERROR', error.status, error.message)
          } else {
            setMessage(t('SearchFailure', {type}))
          }
          setAlert(true)
          setEntries([])
        }
      })
    return () => { mounted = false }
  }, [fhirClient,type,t])

  const tableRef = React.useRef<any>(null);

  const groupTitle = (groupData: any) => (
    <Badge
      color='secondary'
      badgeContent={groupData.data.length}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      max={999}
    >
      <span style={{ width: '1em' }}>&nbsp;</span>
    </Badge>
  );
  const rowStyle = (rowData: any) => (rowData.deleted && {
    background: 'linear-gradient(180deg, rgba(255,238,238,1) 0%, rgba(255,238,238,1) 49.5%, rgba(0,0,0,1) 50%, rgba(255,238,238,1) 50.5%, rgba(255,238,238,1) 100%)',
  });
  const deleteResource = (rowData: any) => ({
    isFreeAction: false,
    icon: () => <Icon aria-label="Delete Resource">delete</Icon>,
    tooltip: translations.deleteResource,
    disabled: rowData.deleted,
    onClick: (event: any, rowData: any) => {
      if (rowData.deleted) return
      const id = rowData.resource.id
      if (window.confirm(`${translations.confirmDelete} (${id}) ${rowData.resourceName}?`) && fhirClient) {
        fhirClient?.delete(type + '/' + rowData.resource.id)
          .then((result) => {
            console.log('Delete completed', result)
            rowData.deleted = true
            tableRef.current && tableRef.current.forceUpdate()
          })
          .catch((error) => {
            console.log('Delete failed', error)
            window.alert('Delete failed: ' + error)
          })
      }
    }
  });

  const compareResources = {
    isFreeAction: true,
    icon: () => <Difference />,
    onClick: () => setOpen(true),
    disabled: !smart.onto?.hasDiff,
    tooltip: translations.compareResources,
  }

  const uploadResource = {
    isFreeAction: true,
    icon: () => <NoteAdd />,
    onClick: () => setUploading(true),
    tooltip: translations.uploadResource,
  }

  const renderTags = React.useCallback((rowData: any) => {
    const tags = (rowData.resource.meta.tag || []).filter((tag: any) => tag.system !== 'http://hl7.org/fhir/v3/ObservationValue' && tag.code !== 'SUBSETTED');
    return (
      <React.Fragment>
        {tags.map((tag: any) => (
          <Chip size='small' title={(tag.display ? (tag.display + '\n') : '') + tag.system + '|' + tag.code} label={tag.code} />
        ))}
      </React.Fragment>
    );
  }, []);

  const unaccent = (str: string) => {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
  }

  return (
    <React.Fragment>
      <Snackbar open={alert} autoHideDuration={60000} onClose={handleAlertClose}>
        <Alert elevation={6} variant="filled" onClose={handleAlertClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Card square>
        <CardContent>
          <MaterialTable
            tableRef={tableRef}
            isLoading={loading}
            title={type + ' (' + count + ')'}
            components={{
              Container: props => <Paper {...props} square elevation={0} />,
              // GroupRow: props => (<MTableGroupRowPaged {...props} />)
              // , Pagination: props => <MTablePagination {...props} />
            }}
            columns={[
              { title: translations.id, field: 'resource.id', grouping: false, hidden: true, width: 0, },
              {
                title: translations.title, field: 'resourceName', defaultSort: 'asc',
                tooltip: 'Title otherwise Name',
                width: '30%',
                customFilterAndSearch: function(filterValue, row, columnDef) {
                  const value = row.resourceName;
                  return value && unaccent(value.toString()).includes(unaccent(filterValue));
                },
                // defaultGroupOrder: 0,
              },
              { title: translations.url, field: 'resource.url', hidden: true, width: 0, },

              { title: translations.version, field: 'resource.version' },
              { title: translations.status, field: 'resource.status', },
              { title: translations.domain, field: 'domain', tooltip: "The hostname part of the Resource's URL" },
              { title: translations.lastUpdated, field: 'resource.meta.lastUpdated', type: 'date', defaultSort: 'desc', hidden: true, width: 0, },
              { title: translations.publisher, field: 'resource.publisher', hidden: true, width: 0, },
              { title: translations.description, field: 'resource.description', hidden: true, width: '30%', },
              { title: translations.purpose, field: 'resource.purpose', hidden: true, width: 0, },
              {
                title: translations.tags, field: 'resource.meta.tag', hidden: true, width: 0,
                grouping: false,
                render: renderTags,
              },

              {
                title: <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>{translations.synd}<RssFeed /></div>,
                render: (rowData) => (<ResourceSyndication resource={rowData.resource} />),
              }

            ]}
            options={{
              actionsColumnIndex: -1,
              columnsButton: true,
              grouping: true,
              groupTitle: groupTitle,
              paging: true,
              padding: 'dense',
              pageSize: 10,
              pageSizeOptions: [5, 10, 25, 50, 100],
              rowStyle: rowStyle,
              showFirstLastPageButtons: false,
              maxColumnSort: 'all_columns',
            }}
            actions={[
              // {
              //     icon: 'open_in_browser',
              //     tooltip: 'View with Shrimp',
              //     disabled: type !== 'ValueSet',
              //     onClick: (event, rowData) => {
              //         if (rowData.deleted) return
              //         const id = encodeURIComponent(rowData.resource.id);
              //         const uri = encodeURIComponent(rowData.resource.url);
              //         const version = encodeURIComponent(rowData.resource.version);
              //         const serverUrl = encodeURIComponent(fhirClient.state.serverUrl);
              //         const url = 'http://ontoserver.csiro.au/shrimp/' + (
              //             type === 'ValueSet'
              //             ? 'vs.html?valueSetId='+id+'&fhir='+serverUrl
              //             : '?system='+uri+'&version='+version
              //         )

              //         window.open(url, '_blank')
              //     }
              // },
              compareResources,
              uploadResource,
              deleteResource,
            ]}
            detailPanel={[
              (rowData: any) => (
                rowData.resource.meta?.versionId === '1' || rowData.deleted ? BLANK :
                  {
                    icon: () => <Icon>difference</Icon>,
                    tooltip: translations.changes,
                    render: () => {
                      const id = rowData.resource.id
                      return (<FhirPatchView type={type} id={id}></FhirPatchView>)
                    }
                  }),
              (rowData: any) => (
                {
                  icon: () => <Icon aria-label="Show History">schedule</Icon>,
                  openIcon: () => <Icon aria-label="Hide History">history</Icon>,
                  disabled: rowData.deleted || (rowData.resource.meta?.versionId === '1'),
                  tooltip: (!rowData.deleted && (rowData.resource.meta?.versionId !== '1')) && translations.history,
                  render: () => <ResourceHistory style={{ marginLeft: '2em' }} type={type} resource={rowData.resource} />,
                }),
              (rowData: any) => ({
                icon: () => <Icon aria-label="Show Validation">bug_report</Icon>,
                disabled: rowData.deleted,
                tooltip: translations.validation,
                render: () => {
                  const id = rowData.resource.id
                  return (
                    <Validation
                      id="validation"
                      resource={`${type}/${id}`}
                      title={rowData.resourceName}
                    />
                  )
                }
              }),
              (rowData: any) => ({
                icon: () => <Icon aria-label="Show Narrative">note</Icon>,
                openIcon: () => <Icon aria-label="Hide Narrative">description</Icon>,
                disabled: rowData.noNarrative || rowData.deleted,
                tooltip: !rowData.noNarrative && !rowData.deleted && translations.narrative,
                render: () => {
                  const id = rowData.resource.id;
                  return (
                    <Narrative type={type} id={id} noContent={() => {
                      rowData.noNarrative = true;
                    }} />
                  )
                }
              }),
              (rowData: any) => ({
                icon: () => <Icon aria-label="Show Details">chevron_right</Icon>,
                openIcon: () => <Icon aria-label="Hide Details">expand_more</Icon>,
                disabled: rowData.deleted,
                tooltip: translations.details,
                render: () => {
                  return (
                    <ResourceDetails resource={rowData.resource} />
                  )
                }
              }),
            ]}
            // onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
            data={entries}
            localization={{
              grouping: {
                placeholder: t('GroupPlaceholder'),
                groupedBy: t('GroupBy'),
              },
              header: {
                actions: t('Actions'),
              },
              pagination: {
                nextAriaLabel: t('Next Page'),
                previousAriaLabel: t('Previous Page'),
                firstAriaLabel: t('First Page'),
                lastAriaLabel: t('Last Page'),
                labelRowsPerPage: t('rows label'),
                labelDisplayedRows: t('rows range'),
              },
              toolbar: {
                searchTooltip: t('Search'),
                searchPlaceholder: t('Search'),
              },
            }}
          />
          {/* <Validation
                        id="validate-confirmation"
                        resource={validate?.id}
                        title={validate?.title}
                        open={validate !== undefined}
                        onClose={() => { setValidate(undefined) }}
                    /> */}
        </CardContent>
      </Card>
      <ResourceCompare
        type={type}
        data={tableRef?.current?.dataManager.pagedData || []}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ResourceUpload
        type={type}
        open={uploading}
        onClose={() => setUploading(false)}
      />
    </React.Fragment>
  )
});
