import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import Navigator from './Navigator';
import Body from './Body';
import Copyright from './Copyright';
import Error from './Error';
import Login from './Login';
import Launcher from './Launcher';
import SmartProvider from './SmartProvider';

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#007DB8',
      dark: '#006db3',
    },
    secondary: {
      main: '#EB0000',
    },
    text: {
      secondary: '#6A6C6D',
    },
  },
  typography: {
    body1: {
      fontSize: '14px',
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: false,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#18202c',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#404854',
          marginTop: theme.spacing(2),
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingTop: 1,
          paddingBottom: 1,
          color: 'rgba(255, 255, 255, 0.7)',
          '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 192;
const background = '#eaeff1';

const AppDivComponent = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const NavComponent = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

const FooterComponent = styled('footer')(({ theme }) => ({
  padding: theme.spacing(1),
  background: background,
}));

function Paperbase() {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline/>
        <Switch>
          <Route key="launch" path="/launch">
            <AppDivComponent>
              <Launcher/>
            </AppDivComponent>
          </Route>
          <Route key="error" path="/error" children={<Error/>}/>
          <Route key="login" path="/login">
            <AppDivComponent>
              <Login/>
            </AppDivComponent>
          </Route>
          <Route key="home" path="/" exact><Redirect to="/about"/></Route>
          <Route key="other">
            <SmartProvider>
              <NavComponent>
                <Navigator slotProps={{ paper: { style: { width: drawerWidth }} }}/>
              </NavComponent>
              <AppDivComponent>
                <Body/>
                <FooterComponent>
                  <Copyright/>
                </FooterComponent>
              </AppDivComponent>
            </SmartProvider>
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default Paperbase;
